var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: "限定品牌",
            visible: _vm.Brand,
            center: "",
            width: "20%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.Brand = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "250px", "overflow-y": "auto" } },
            [
              _c("el-tree", {
                ref: "tree",
                attrs: {
                  data: _vm.treedata,
                  "show-checkbox": "",
                  "default-expand-all": "",
                  "check-strictly": true,
                  "check-on-click-node": "",
                  "node-key": "id",
                  "highlight-current": "",
                  props: _vm.defaultProps,
                },
                on: { "check-change": _vm.change },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.Brand = !_vm.Brand
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }