<!--
 * @Author: 候怀烨
 * @Date: 2021-01-07 16:42:36
 * @LastEditTime: 2021-03-27 11:11:08
 * @LastEditors: Please set LastEditors
 * @Description: 累计促销
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\moreEvents\cumulativePromotion\index.vue
-->
<template>
  <!-- NAME[epic=动销] 累计促销 -->
  <div style="padding: 20px">
    <el-row>
      <el-col :span="20">
        <el-form ref="form" inline :model="form">
          <el-form-item prop="accum_name">
            <el-input
              v-model="form.accum_name"
              placeholder="请输入活动名称"
              style="width: 150px"
            ></el-input>
          </el-form-item>
          <el-form-item prop="goods_name">
            <el-input
              v-model="form.goods_name"
              style="width: 150px"
              placeholder="商品名称、助记码"
              suffix-icon="el-icon-search"
            ></el-input>
          </el-form-item>
          <el-form-item prop="status">
            <el-select v-model="form.status" style="width: 150px">
              <el-option
                v-for="item in Status"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="hanlderInquire">查 询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="handlerReset">重 置</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" @click="handlerAdd">创建累计促销</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-table stripe :data="tableData">
        <el-table-column type="index"></el-table-column>
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="220" fixed="right">
          <template #default="{ row }">
            <el-button type="text" @click="handlerlinkd(row)">查看</el-button>
            <el-button type="text" @click="handlermodify(row)">修改</el-button>
            <el-popconfirm
              v-show="row.status_text != '停止'"
              title="您真的要停止这个活动吗？"
              @confirm="handlerstop(row)"
            >
              <el-button slot="reference" style="margin-left: 10px" type="text">
                停止
              </el-button>
            </el-popconfirm>
            <el-popconfirm
              v-show="row.is_close == 1"
              title="您真的要删除这个活动吗？"
              @confirm="handlerdelete(row)"
            >
              <el-button slot="reference" style="margin-left: 10px" type="text">
                删除
              </el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
        <!-- width="" -->
      </el-table>
    </el-row>
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        background
        :current-page="form.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
    <add-promotion
      ref="promotion"
      @getcomponents="getcomponents"
    ></add-promotion>
    <link-promotion ref="link"></link-promotion>
  </div>
</template>

<script>
  import LinkPromotion from './components/LinkPromotion.vue'
  import AddPromotion from './components/AddPromotion.vue'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      AddPromotion,
      LinkPromotion,
    },
    data() {
      return {
        form: {
          pageNo: 1,
          pageSize: 10,
          accum_name: '',
          goods_name: '',
          status: '',
        },
        tableData: [],
        total: 0,
        Status: [
          {
            id: 1,
            name: '进行中',
          },
          {
            id: 2,
            name: '已结束',
          },
          {
            id: 3,
            name: '未开始',
          },
          {
            id: 4,
            name: '已停止',
          },
        ],
        colemd: [
          {
            label: '活动名称',
            align: 'center',
            prop: 'accum_name',
            width: '220',
          },
          {
            label: '开始时间',
            align: 'center',
            prop: 'start_time',
            width: '150',
          },
          {
            label: '结束时间',
            align: 'center',
            prop: 'end_time',
            width: '150',
          },
          {
            label: '状态',
            align: 'center',
            prop: 'status_text',
            width: '120',
          },
        ],
        url: {
          list: '/promoteAdmin/accum/index',
          delete: '/promoteAdmin/accum/delete',
          stop: '/promoteAdmin/accum/status',
        },
      }
    },
    mounted() {
      this.hanlderInquire()
    },
    methods: {
      getcomponents() {
        this.hanlderInquire()
      },
      handlermodify(row) {
        this.$refs.promotion.handlerdata(row)
        this.$refs.promotion.Promotion = true
      },
      handlerlinkd(row) {
        this.$refs.link.handlerData(row)
        this.$refs.link.linkshow = true
      },
      handlerAdd() {
        this.$refs.promotion.Promotion = true
      },
      handlerReset() {
        this.$refs['form'].resetFields()
        this.hanlderInquire()
      },
      handlerstop(row) {
        console.log(row)
        postAction(this.url.stop, { id: row.id })
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.hanlderInquire()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerdelete(row) {
        console.log(row)
        postAction(this.url.delete, { id: row.id })
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.hanlderInquire()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
      hanlderInquire() {
        this.form.pageNo = 1
        this.form.pageSize = 10
        this.handlerlist()
      },
      handlerlist() {
        postAction(this.url.list, this.form)
          .then((res) => {
            console.log(res)
            this.tableData = res.data
            this.total = res.totalCount
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
</script>

<style></style>
