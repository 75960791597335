var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { modal: false, visible: _vm.linkshow, title: "活动详情" },
          on: {
            "update:visible": function ($event) {
              _vm.linkshow = $event
            },
          },
        },
        [
          _c("div", [
            _c(
              "div",
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: { model: _vm.form, "label-width": "100px" },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "活动名称：", prop: "accum_name" },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "250px" },
                              attrs: {
                                disabled: "",
                                placeholder: "请输入活动名称",
                              },
                              model: {
                                value: _vm.form.accum_name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "accum_name", $$v)
                                },
                                expression: "form.accum_name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "起止时间：", prop: "time" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "250px" },
                              attrs: {
                                disabled: "",
                                type: "daterange",
                                "range-separator": "-",
                                "value-format": "yyyy-MM-dd",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                              },
                              model: {
                                value: _vm.time,
                                callback: function ($$v) {
                                  _vm.time = $$v
                                },
                                expression: "time",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "限定业务员：" } },
                          [
                            _c(
                              "span",
                              { staticStyle: { "margin-right": "15px" } },
                              [_vm._v(_vm._s(_vm.user_data) + "人")]
                            ),
                          ]
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "活动备注：", prop: "remark" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "250px" },
                              attrs: {
                                disabled: "",
                                placeholder: "活动备注",
                                type: "textarea",
                                rows: 2,
                              },
                              model: {
                                value: _vm.form.remark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "remark", $$v)
                                },
                                expression: "form.remark",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._l(_vm.form.acc_data, function (accList) {
                          return _c(
                            "el-card",
                            { key: accList.phase },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "活动方案：",
                                    prop: "acc_type",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "1", disabled: "" },
                                      model: {
                                        value: accList.acc_type,
                                        callback: function ($$v) {
                                          _vm.$set(accList, "acc_type", $$v)
                                        },
                                        expression: "accList.acc_type",
                                      },
                                    },
                                    [_vm._v(" 按商品累计 ")]
                                  ),
                                  _c(
                                    "el-table",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        stripe: "",
                                        data: accList.goodlist,
                                        height: "200px",
                                      },
                                    },
                                    [
                                      _vm._l(
                                        _vm.colemd1,
                                        function (list, index) {
                                          return _c("el-table-column", {
                                            key: index,
                                            attrs: {
                                              label: list.label,
                                              align: list.align,
                                              prop: list.prop,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                list.label == "活动商品"
                                                  ? {
                                                      key: "default",
                                                      fn: function (ref) {
                                                        var row = ref.row
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                row.goods_name
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    }
                                                  : list.label == "单位"
                                                  ? {
                                                      key: "default",
                                                      fn: function (ref) {
                                                        var row = ref.row
                                                        return [
                                                          _c(
                                                            "el-select",
                                                            {
                                                              staticStyle: {
                                                                width: "70px",
                                                              },
                                                              attrs: {
                                                                disabled: "",
                                                                placeholder:
                                                                  "选择单位",
                                                              },
                                                              model: {
                                                                value:
                                                                  row.unit_id,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      row,
                                                                      "unit_id",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "row.unit_id",
                                                              },
                                                            },
                                                            _vm._l(
                                                              row.arr_unit,
                                                              function (item) {
                                                                return _c(
                                                                  "el-option",
                                                                  {
                                                                    key: item.id,
                                                                    attrs: {
                                                                      label:
                                                                        item.unit_name,
                                                                      value:
                                                                        item.id,
                                                                    },
                                                                  }
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    }
                                                  : {
                                                      key: "default",
                                                      fn: function (ref) {
                                                        var row = ref.row
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                row[list.prop]
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        }
                                      ),
                                      _c("el-table-column", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "header",
                                              fn: function (ref) {
                                                return [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      model: {
                                                        value: accList.by_type,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            accList,
                                                            "by_type",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "accList.by_type",
                                                      },
                                                    },
                                                    [
                                                      _c("el-option", {
                                                        attrs: {
                                                          label: "按数量累计",
                                                          value: "1",
                                                        },
                                                      }),
                                                      _c("el-option", {
                                                        attrs: {
                                                          label: "按金额累计",
                                                          value: "2",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var row = ref.row
                                                return [
                                                  accList.by_type == 1
                                                    ? _c("el-input", {
                                                        attrs: {
                                                          placeholder: "数量",
                                                          disabled: "",
                                                        },
                                                        model: {
                                                          value: row.goods_num,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              row,
                                                              "goods_num",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "row.goods_num",
                                                        },
                                                      })
                                                    : _c("el-input", {
                                                        attrs: {
                                                          placeholder: "金额",
                                                          disabled: "",
                                                        },
                                                        model: {
                                                          value:
                                                            row.goods_money,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              row,
                                                              "goods_money",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "row.goods_money",
                                                        },
                                                      }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "el-row",
                                    { staticStyle: { padding: "10px" } },
                                    [
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: { label: "2", disabled: "" },
                                          model: {
                                            value: accList.acc_type,
                                            callback: function ($$v) {
                                              _vm.$set(accList, "acc_type", $$v)
                                            },
                                            expression: "accList.acc_type",
                                          },
                                        },
                                        [_vm._v(" 按金额累计 ")]
                                      ),
                                      _c("span", [_vm._v("累计下单=>")]),
                                      _c("el-input", {
                                        staticStyle: { width: "150px" },
                                        attrs: {
                                          disabled: "",
                                          placeholder: "请输入累计下单数量",
                                          onkeyup:
                                            "value=value.replace(/[^\\d.]/g,'') ",
                                        },
                                        model: {
                                          value: accList.acc_total_amount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              accList,
                                              "acc_total_amount",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "accList.acc_total_amount",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "20px",
                                          },
                                        },
                                        [_vm._v("限定下单品牌:")]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(accList.brandlist.length)
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-table",
                                    {
                                      attrs: {
                                        stripe: "",
                                        data: accList.givelist,
                                        height: "300px",
                                      },
                                    },
                                    _vm._l(_vm.colemd, function (list, index) {
                                      return _c("el-table-column", {
                                        key: index,
                                        attrs: {
                                          label: list.label,
                                          align: list.align,
                                          prop: list.prop,
                                          width: "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            list.label == "活动奖品"
                                              ? {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            row.goods_name
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                }
                                              : list.label == "奖品数量"
                                              ? {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            row.goods_num
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                }
                                              : list.label == "单位"
                                              ? {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var row = ref.row
                                                    return [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticStyle: {
                                                            width: "70px",
                                                          },
                                                          attrs: {
                                                            disabled: "",
                                                            placeholder:
                                                              "选择单位",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.unitChange(
                                                                $event,
                                                                row
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value: row.unit_id,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                row,
                                                                "unit_id",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "row.unit_id",
                                                          },
                                                        },
                                                        _vm._l(
                                                          row.arr_unit,
                                                          function (item) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: item.id,
                                                                attrs: {
                                                                  label:
                                                                    item.unit_name,
                                                                  value:
                                                                    item.id,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                }
                                              : list.label == "货值"
                                              ? {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            Number(
                                                              row.goods_num *
                                                                row.goods_money
                                                            ).toFixed(2)
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                }
                                              : {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            row[list.prop]
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  },
                                                },
                                          ],
                                          null,
                                          true
                                        ),
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "奖励现金：",
                                    prop: "gift_money",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "250px" },
                                    attrs: {
                                      disabled: "",
                                      placeholder: "奖励金额",
                                    },
                                    model: {
                                      value: accList.gift_money,
                                      callback: function ($$v) {
                                        _vm.$set(accList, "gift_money", $$v)
                                      },
                                      expression: "accList.gift_money",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "修改奖品：",
                              prop: "flag_edit_prize",
                            },
                          },
                          [
                            _c("el-switch", {
                              attrs: {
                                disabled: "",
                                "active-value": 1,
                                "inactive-value": 0,
                              },
                              model: {
                                value: _vm.form.flag_edit_prize,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "flag_edit_prize", $$v)
                                },
                                expression: "form.flag_edit_prize",
                              },
                            }),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "20px" } },
                              [_vm._v("允许超额修改：")]
                            ),
                            _c("el-switch", {
                              attrs: {
                                disabled: "",
                                "active-value": 1,
                                "inactive-value": 0,
                              },
                              model: {
                                value: _vm.form.flag_edit_excess,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "flag_edit_excess", $$v)
                                },
                                expression: "form.flag_edit_excess",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { prop: "flag_abnormal" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.form.flag_abnormal,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "flag_abnormal", $$v)
                                  },
                                  expression: "form.flag_abnormal",
                                },
                              },
                              _vm._l(_vm.cities, function (city) {
                                return _c(
                                  "el-radio",
                                  { key: city.id, attrs: { label: city.id } },
                                  [_vm._v(" " + _vm._s(city.name) + " ")]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.linkshow = !_vm.linkshow
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }