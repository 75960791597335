<!--
 * @Author: 候怀烨
 * @Date: 2021-01-15 11:15:03
 * @LastEditTime: 2021-01-15 12:06:17
 * @LastEditors: Please set LastEditors
 * @Description: 限定品牌
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\moreEvents\cumulativePromotion\components\Brand.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      title="限定品牌"
      :visible.sync="Brand"
      center
      width="20%"
    >
      <div style="height: 250px; overflow-y: auto">
        <el-tree
          ref="tree"
          :data="treedata"
          show-checkbox
          default-expand-all
          :check-strictly="true"
          check-on-click-node
          node-key="id"
          highlight-current
          :props="defaultProps"
          @check-change="change"
        ></el-tree>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">保 存</el-button>
        <el-button @click="Brand = !Brand">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    data() {
      return {
        Brand: false,
        treedata: [],
        treeitme: [],
        defaultProps: {
          children: 'children',
          label: 'brand_name',
        },
        url: {
          pinpai: '/baseAdmin/common/goods-brand-option',
        },
      }
    },
    mounted() {
      this.handlerTree()
    },
    methods: {
      submit() {
        console.log('tag', '')
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$emit('Brandlit', this.treeitme)
        console.log(this.treeitme, 'lalal')
        this.Brand = false
      },
      change() {
        let res = this.$refs.tree.getCheckedNodes()
        console.log(res, '')
        var b = []
        b = res
        var a = new Array()
        b.forEach((item) => {
          a.push(item.id)
        })
        this.treeitme = a
      },
      handlerTree() {
        postAction(this.url.pinpai, {})
          .then((res) => {
            console.log(res)
            this.treedata = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
    },
  }
</script>

<style></style>
