<!--
 * @Author: 候怀烨
 * @Date: 2021-01-13 14:40:34
 * @LastEditTime: 2021-03-29 11:10:13
 * @LastEditors: Please set LastEditors
 * @Description: 添加累计活动弹框
 * @FilePath: \sd-vue-admin\src\views\project\movablepin\moreEvents\cumulativePromotion\components\AddPromotion.vue
-->
<template>
  <div>
    <el-dialog
      top="7vh"
      width="1100px"
      :modal="false"
      title="添加累计活动"
      :visible.sync="Promotion"
      center
    >
      <div>
        <el-row>
          <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="活动名称：" prop="accum_name">
              <el-input
                v-model="form.accum_name"
                placeholder="请输入活动名称"
                style="width: 250px"
              ></el-input>
            </el-form-item>
            <el-form-item label="起止时间：" prop="time">
              <el-date-picker
                v-model="time"
                type="daterange"
                range-separator="-"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 250px"
                @change="handlerTiem"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="限定业务员：">
              <span style="margin-right: 15px">{{ user_data }}人</span>
              <el-button type="primary" @click="handleSet()">设置</el-button>
            </el-form-item>
            <el-form-item label="活动备注：" prop="remark">
              <el-input
                v-model="form.remark"
                placeholder="活动备注"
                type="textarea"
                :rows="2"
                style="width: 250px"
              ></el-input>
            </el-form-item>
            <el-card
              v-for="(accList, accIndex) in form.acc_data"
              :key="accIndex"
            >
              <el-form-item
                :label="'累计方案' + (accIndex + 1) + ':'"
                prop="acc_type"
              >
                <!-- 按商品累计 -->
                <el-radio v-model="accList.acc_type" label="1">
                  按指定商品累计
                </el-radio>

                <el-table stripe :data="accList.goodlist" height="200px">
                  <el-table-column
                    v-for="(list, index) in colemd1"
                    :key="index"
                    :label="list.label"
                    :align="list.align"
                    :prop="list.prop"
                    :width="list.label == '活动商品' ? 240 : ''"
                  >
                    <template
                      v-if="list.label == '活动商品'"
                      #default="{ $index, row }"
                    >
                      <goods-search
                        :f-key="row.goods_name"
                        @add-rows="addRows1($event, accIndex)"
                        @select-goods-all="
                          selectGoods1($event, row, $index, accIndex)
                        "
                      ></goods-search>
                    </template>
                    <template
                      v-else-if="list.label == '单位'"
                      #default="{ row }"
                    >
                      <el-select
                        v-model="row.unit_id"
                        placeholder="选择单位"
                        style="width: 70px"
                        @change="unitChange($event, row)"
                      >
                        <el-option
                          v-for="item in row.arr_unit"
                          :key="item.id"
                          :label="item.unit_name"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </template>
                    <template v-else #default="{ row }">
                      {{ row[list.prop] }}
                    </template>
                  </el-table-column>
                  <el-table-column>
                    <template slot="header" slot-scope="{}">
                      <el-select v-model="accList.by_type">
                        <el-option label="按数量累计" value="1"></el-option>
                        <el-option label="按金额累计" value="2"></el-option>
                      </el-select>
                    </template>
                    <template #default="{ row }">
                      <el-input
                        v-if="accList.by_type == 1"
                        v-model="row.goods_num"
                        placeholder="数量"
                      />
                      <el-input
                        v-else
                        v-model="row.goods_money"
                        placeholder="金额"
                      />
                    </template>
                  </el-table-column>
                  <!--                  <el-table-column>
                    <template slot="header">
                      <el-checkbox :checked="checked1" @change="handlerrsdio">
                        累计金额
                      </el-checkbox>
                      <el-radio v-model="accList.by_type" :label="2">
                        累计金额
                      </el-radio>
                    </template>
                    <template #default="{ row }">
                      <el-input v-model="accList.by_type" placeholder="金额" />
                    </template>
                  </el-table-column>-->
                </el-table>
                <!-- 按金额累计 -->
                <el-row style="padding: 10px">
                  <el-radio v-model="accList.acc_type" label="2">
                    按开单金额累计
                  </el-radio>
                  <span>累计下单=></span>
                  <el-input
                    v-model="accList.acc_total_amount"
                    :disabled="accList.acc_type == 1"
                    placeholder="请输入累计下单数量"
                    style="width: 150px"
                    onkeyup="value=value.replace(/[^\d.]/g,'') "
                  />
                  <span style="margin-left: 20px">限定下单品牌:</span>
                  <span>{{ accList.brandlist.length }}</span>
                  <el-button
                    :disabled="accList.acc_type == 1"
                    type="primary"
                    style="margin-left: 20px"
                    @click="handlerAddbrand(accIndex)"
                  >
                    设 置
                  </el-button>
                </el-row>

                <el-table stripe :data="accList.givelist">
                  <el-table-column
                    v-for="(list, index) in colemd"
                    :key="index"
                    :label="list.label"
                    :align="list.align"
                    :prop="list.prop"
                    :width="list.label == '活动奖品' ? 240 : ''"
                  >
                    <template
                      v-if="list.label == '活动奖品'"
                      #default="{ $index, row }"
                    >
                      <goods-search
                        :f-key="row.goods_name"
                        @add-rows="addRows($event, accIndex)"
                        @select-goods-all="
                          selectGoods($event, row, $index, accIndex)
                        "
                      ></goods-search>
                    </template>
                    <!-- <template v-else-if="list.label == '生产日期'" #default="{ row }">
                  <el-date-picker
                    v-model="row.state_time"
                    style="width: 140px"
                    type="date"
                    placeholder="选择日期"
                  ></el-date-picker>
                </template> -->
                    <template
                      v-else-if="list.label == '奖励数量'"
                      #default="{ row }"
                    >
                      <el-input v-model="row.goods_num" style="width: 80px" />
                    </template>
                    <template
                      v-else-if="list.label == '单位'"
                      #default="{ row }"
                    >
                      <el-select
                        v-model="row.unit_id"
                        placeholder="选择单位"
                        style="width: 70px"
                        @change="unitChange($event, row)"
                      >
                        <el-option
                          v-for="item in row.arr_unit"
                          :key="item.id"
                          :label="item.unit_name"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </template>
                    <template
                      v-else-if="list.label == '货值'"
                      #default="{ row }"
                    >
                      {{
                        (
                          Number(row.goods_num) * Number(row.goods_price)
                        ).toFixed(2)
                      }}
                    </template>
                    <!-- <template v-else-if="list.label == '单价'" #default="{ row }">
                      <el-input
                        v-model="row.goods_price"
                        style="width: 80px"
                        onkeyup="value=value.replace(/[^\d.]/g,'') "
                      />
                    </template> -->
                    <template v-else #default="{ row }">
                      {{ row[list.prop] }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="操作"
                    align="center"
                    fixed="right"
                    width="120"
                  >
                    <template #default="{ row, $index }">
                      <el-button
                        type="text"
                        @click="handlerAdd(row, $index, accIndex)"
                      >
                        添加
                      </el-button>
                      <el-button
                        type="text"
                        @click="handlerDelet(row, $index, accIndex)"
                      >
                        删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
              <el-form-item label="奖励现金：" prop="gift_money">
                <el-input
                  v-model="accList.gift_money"
                  style="width: 250px"
                  placeholder="奖励金额"
                />
              </el-form-item>

              <el-button
                type="text"
                class="button"
                @click="handlerDeleteProgramme(accIndex)"
              >
                删除
              </el-button>
            </el-card>
            <el-button type="primary" @click="handlerAddProgramme">
              <!--              v-show="form.acc_data.length < 5"-->
              添加累计方案
            </el-button>

            <el-form-item label="修改奖品：" prop="flag_edit_prize">
              <el-switch
                v-model="form.flag_edit_prize"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
              <span style="margin-left: 20px">允许超额修改：</span>
              <el-switch
                v-model="form.flag_edit_excess"
                :disabled="form.flag_edit_prize != 1"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </el-form-item>
            <el-form-item prop="flag_abnormal">
              <el-radio-group
                v-model="form.flag_abnormal"
                :disabled="form.flag_edit_prize != 1"
              >
                <el-radio
                  v-for="city in cities"
                  :key="city.id"
                  :label="city.id"
                >
                  {{ city.name }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <set-component
            ref="setComponent"
            @set-userid="setUserId"
          ></set-component>
        </el-row>
        <brand ref="brand" @Brandlit="Brandlit"></brand>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          v-show="Promotiontype == 2"
          type="primary"
          @click="handlersevrse"
        >
          保 存2
        </el-button>
        <el-button
          v-show="Promotiontype == 1"
          type="primary"
          @click="handlersave"
        >
          保 存1
        </el-button>
        <el-button @click="Promotion = !Promotion">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import Brand from './Brand.vue'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import SetComponent from '@/views/project/sale/control/setPrice/components/createEdit/setComponent'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      Brand,
      GoodsSearch,
      SetComponent,
    },
    data() {
      return {
        by_type: '1',
        Promotion: false,
        title: '添加累计活动',
        Promotiontype: 1,
        time: [],
        acc_dataindex: null,
        form: {
          accum_name: '', //活动名称
          remark: '', //活动备注
          start_time: '', //开始时间
          end_time: '', //结束时间
          user_data: '', //限制业务数据  如[1,2,3]
          flag_edit_prize: 1, //是否可更换赠品  0否1可修改
          flag_abnormal: '', //报异常开关 0允许超额 1更换就异常 2超额报异常
          flag_edit_excess: 0, //是否可超额更换赠品  0不可 1可
          acc_data: [
            {
              phase: 1,
              acc_type: '1', //累计方式  1按商品 2按金额
              by_type: '1', //acc_type=1时 累计方式 1按数量 2按金额
              goodlist: [
                {
                  arr_unit: [], //没有用的
                  goods_id: '',
                  goods_price: '', //没有用的
                  goods_name: '',
                  goods_specs: '',
                  unit_id: '',
                  unit_name: '',
                  goods_num: '',
                  goods_money: '',
                },
              ], //按照商品累计  商品数据
              givelist: [
                {
                  arr_unit: [],
                  goods_id: '',
                  goods_name: '',
                  goods_specs: '',
                  unit_id: '',
                  unit_name: '',
                  goods_num: '',
                  goods_price: '',
                },
              ], //赠品数据
              brandlist: [], //按照金额  限制品牌数据 如[1,2,3]
              acc_total_amount: '', //按照金额  累计金额值
              gift_money: '', //奖励现金
            },
          ],
        },
        user_data: 0,
        cities: [
          {
            name: '更换奖品不提示异常',
            id: '0',
          },
          {
            name: '更换奖品提示异常',
            id: '1',
          },
          {
            name: '超额更换奖品提示异常',
            id: '2',
          },
        ],
        colemd1: [
          {
            order: 1,
            label: '活动商品',
            align: 'center',
            prop: 'goods_name',
            width: '220',
          },
          {
            order: 1,
            label: '规格',
            align: 'center',
            prop: 'goods_specs',
            width: '220',
          },
          {
            order: 1,
            label: '单位',
            align: 'center',
            prop: 'unit_id',
            width: '220',
          },
          {
            order: 1,
            label: '售价',
            align: 'center',
            prop: 'goods_price',
            width: '220',
          },
        ],
        colemd: [
          {
            order: 1,
            label: '活动奖品',
            align: 'center',
            prop: 'goods_name',
            width: '220',
          },
          {
            order: 3,
            label: '规格',
            align: 'center',
            prop: 'goods_specs',
            width: '100',
          },
          {
            order: 2,
            label: '单位',
            align: 'center',
            prop: 'unit_id',
            width: '80',
          },

          {
            order: 4,
            label: '单价',
            align: 'center',
            prop: 'goods_price',
            width: '120',
          },
          {
            order: 6,
            label: '奖励数量',
            align: 'center',
            prop: 'goods_num',
            width: '170',
          },
          {
            order: 5,
            label: '货值',
            align: 'center',
            prop: 'goods_money',
            width: '120',
          },
        ],
        url: {
          // Addlist: '/promoteAdmin/accum/create',
          // Details: '/promoteAdmin/accum/detail',
          // entdlist: '/promoteAdmin/accum/update',
          Addlist: '/promoteAdmin/accum/create-new',
          Details: '/promoteAdmin/accum/detail-new',
          entdlist: '/promoteAdmin/accum/update-new',
        },
      }
    },
    computed: {
      checked() {
        if (this.form.by_type == '1') {
          return true
        } else {
          return false
        }
      },
      checked1() {
        if (this.form.by_type == '2') {
          return true
        } else {
          return false
        }
      },
    },
    watch: {
      'form.by_type'(val) {
        console.log(val, '类型')
      },
      Promotion(val) {
        if (!val) {
          this.title = '添加累计促销'
          this.Promotiontype = 1
          this.user_data = 0
          this.form = {
            accum_name: '', //活动名称
            remark: '', //活动备注
            start_time: '', //开始时间
            end_time: '', //结束时间
            user_data: '', //限制业务数据  如[1,2,3]
            flag_edit_prize: 1, //是否可更换赠品  0否1可修改
            flag_abnormal: '', //报异常开关 0允许超额 1更换就异常 2超额报异常
            flag_edit_excess: 0, //是否可超额更换赠品  0不可 1可
            acc_data: [
              {
                phase: 1,
                acc_type: '1', //累计方式  1按商品 2按金额
                by_type: '1', //acc_type=1时 累计方式 1按数量 2按金额
                goodlist: [
                  {
                    arr_unit: [], //没有用的
                    goods_id: '',
                    goods_price: '', //没有用的
                    goods_name: '',
                    goods_specs: '',
                    unit_id: '',
                    unit_name: '',
                    goods_num: '',
                    goods_money: '',
                  },
                ], //按照商品累计  商品数据
                givelist: [
                  {
                    arr_unit: [],
                    goods_id: '',
                    goods_name: '',
                    goods_specs: '',
                    unit_id: '',
                    unit_name: '',
                    goods_num: '',
                    goods_price: '',
                  },
                ], //赠品数据
                brandlist: [], //按照金额  限制品牌数据 如[1,2,3]
                acc_total_amount: '', //按照金额  累计金额值
                gift_money: '', //奖励现金
              },
            ],
          }
          this.time = []
        }
      },
    },
    methods: {
      handlerDeleteProgramme(accindex) {
        if (this.form.acc_data.length < 2) {
          this.$message({
            message: '累计方案不能为空',
            type: 'warning',
          })
        } else {
          this.form.acc_data.splice(accindex, 1)
        }
      },
      handlerAddProgramme() {
        this.form.acc_data.push({
          phase: this.form.acc_data.length + 1,
          acc_type: '1', //累计方式  1按商品 2按金额
          by_type: '1', //acc_type=1时 累计方式 1按数量 2按金额
          goodlist: [
            {
              arr_unit: [], //没有用的
              goods_id: '',
              goods_price: '', //没有用的
              goods_name: '',
              goods_specs: '',
              unit_id: '',
              unit_name: '',
              goods_num: '',
              goods_money: '',
            },
          ], //按照商品累计  商品数据
          givelist: [
            {
              arr_unit: [],
              goods_id: '',
              goods_name: '',
              goods_specs: '',
              unit_id: '',
              unit_name: '',
              goods_num: '',
              goods_price: '',
            },
          ], //赠品数据
          brandlist: [], //按照金额  限制品牌数据 如[1,2,3]
          acc_total_amount: '', //按照金额  累计金额值
          gift_money: '', //奖励现金
        })
      },
      handlerdata(row) {
        this.title = '编辑累计促销'
        this.Promotiontype = 2
        postAction(this.url.Details, { id: row.id })
          .then((res) => {
            console.log(res)
            this.form.accum_name = res.data.accum_name
            this.form.id = res.data.id
            this.form.remark = res.data.remark
            this.form.start_time = res.data.start_time
            this.form.end_time = res.data.end_time
            this.form.flag_edit_prize = Number(res.data.flag_edit_prize)
            this.time = new Array(res.data.start_time, res.data.end_time)
            this.form.flag_edit_excess = Number(this.form.flag_edit_excess)
            this.user_data = res.data.user_data.length
            var acc = res.data.phase_data.map((list) => {
              var accdata = { ...list.info }
              if (list.give_list) {
                console.log('进来了吗')
                accdata.givelist = list.give_list
              } else {
                accdata.givelist = [
                  {
                    arr_unit: [],
                    goods_id: '',
                    goods_name: '',
                    goods_specs: '',
                    unit_id: '',
                    unit_name: '',
                    goods_num: '',
                    goods_price: '',
                  },
                ]
              }
              if (list.goods_list) {
                accdata.goodlist = list.goods_list
              } else {
                cdata.goodlist = [
                  {
                    arr_unit: [], //没有用的
                    goods_id: '',
                    goods_price: '', //没有用的
                    goods_name: '',
                    goods_specs: '',
                    unit_id: '',
                    unit_name: '',
                    goods_num: '',
                    goods_money: '',
                  },
                ]
              }
              if (list.brand_list) {
                console.log('进来了吗2')
                accdata.brandlist = list.brand_list
              } else {
                accdata.brandlist = []
              }
              console.log(accdata)
              accdata.phase = Number(accdata.phase)
              return accdata
            })
            this.form.acc_data = acc
            console.log(acc, '整理后的数据')
          })
          .catch((err) => {
            console.log((err) => {
              console.log(err)
            })
          })
      },
      handlersevrse() {
        var data = JSON.parse(JSON.stringify(this.form))
        data.acc_data = data.acc_data.map((list) => {
          list.goodlist = list.goodlist.filter((item) => {
            return item.goods_name != '' && item.goods_name != undefined
          })
          list.givelist = list.givelist.filter((item) => {
            return item.goods_name != '' && item.goods_name != undefined
          })
          return list
        })
        data.acc_data = JSON.stringify(data.acc_data)

        // if (goods_data.length > 0) {
        postAction(this.url.entdlist, data)
          .then((res) => {
            console.log(res)
            this.Promotion = false
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.$refs['form'].resetFields()

            this.$emit('getcomponents')
          })
          .catch((err) => {
            console.log(err)
          })
        // } else {
        //   this.$message({
        //     message: '提醒,奖励商品不能为空!',
        //     type: 'warning',
        //   })
        // }
      },

      //添加累计促销活动的代码
      handlersave() {
        // var prize_data = this.listDatas.filter((item) => {
        //   return item.goods_name != ''
        // })
        // var goods_data = this.tableData.filter((item) => {
        //   return item.goods_name != ''
        // })
        // // this.form.goodlist = JSON.stringify(prize_data)
        // if (this.form.acc_type == 1) {
        //   this.$set(this.form, 'goodlist', JSON.stringify(prize_data))
        // }
        // this.form.givelist = JSON.stringify(goods_data)
        // if (goods_data.length > 0) {
        var data = JSON.parse(JSON.stringify(this.form))
        data.acc_data = data.acc_data.map((list) => {
          list.goodlist = list.goodlist.filter((item) => {
            return item.goods_name != '' && item.goods_name != undefined
          })
          console.log(list.goodlist, 'goodlist')
          list.givelist = list.givelist.filter((item) => {
            return item.goods_name != '' && item.goods_name != undefined
          })
          console.log(list.givelist, 'givelist')
          return list
        })
        console.log(data.acc_data, 'r')
        data.acc_data = JSON.stringify(data.acc_data)
        postAction(this.url.Addlist, data)
          .then((res) => {
            console.log(res)
            this.Promotion = false
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.$refs['form'].resetFields()
            this.$emit('getcomponents')
          })
          .catch((err) => {
            console.log(err)
          })
        // } else {
        //   this.$message({
        //     message: '提醒,奖励商品不能为空!',
        //     type: 'warning',
        //   })
        // }
      },
      Brandlit(val) {
        console.log(val, '传过来的值')
        this.form.acc_data[this.acc_dataindex].brandlist = val
      },
      handlerAddbrand(index) {
        this.acc_dataindex = index
        this.$refs.brand.Brand = true
      },
      handlerchecked(val) {
        console.log(val)
        // if (val == true) {
        //   this.form.by_type = 1
        // } else {
        //   this.form.by_type = 2
        // }
      },
      handlerrsdio(val) {
        console.log(val)
        // if (val == true) {
        //   this.form.by_type = 2
        // } else {
        //   this.form.by_type = 1
        // }
        // this.$set(this.form, 'by_type', 2)
        // console.log(this.form.by_type)
        // this.$forceUpdate()
      },
      selectGoods1(event, row, index, accindex) {
        console.log(event, row.goods_name, index, accindex, '1shenme')
        var data = {
          arr_unit: event.arr_unit, //没有用的
          goods_id: event.goods_id,
          goods_price: event.goods_price, //没有用的
          goods_name: event.goods_name,
          goods_specs: event.specs,
          unit_id: event.unit_id,
          unit_name: event.unit_name,
          goods_num: event.quantity,
          goods_money: '',
        }
        // Object.assign(row, event)
        Object.assign(row, data)
        console.log(row.goods_name, '商品名称')
        if (
          this.form.acc_data[accindex].goodlist[
            this.form.acc_data[accindex].goodlist.length - 1
          ].goods_name == ''
        ) {
          this.form.acc_data[accindex].goodlist.pop()
        } else {
          var a = {
            arr_unit: [], //没有用的
            goods_id: '',
            goods_price: '', //没有用的
            goods_name: '',
            goods_specs: '',
            unit_id: '',
            unit_name: '',
            goods_num: '',
            goods_money: '',
          }
          // this.form.acc_data[accindex].goodlist.push(a)
        }
      },
      addRows1(val, accindex) {
        console.log('添加rows', val)
        console.log(val)
        if (val.length !== 0) {
          val.forEach((item) => {
            var a = {
              arr_unit: item.arr_unit, //没有用的
              goods_id: item.goods_id,
              goods_price: item.goods_price, //没有用的
              goods_name: item.goods_name,
              goods_specs: item.specs,
              unit_id: item.unit_id,
              unit_name: item.unit_name,
              goods_num: event.quantity,
              goods_money: '',
            }
            item.goodsName = item.goods_name
            // this.form.acc_data[accindex].goodlist.splice(
            //   this.form.acc_data[accindex].goodlist.length - 1,
            //   0,
            //   a
            // )
            this.form.acc_data[accindex].goodlist=[a]
          })
          this.$message.success('添加成功')
        }
      },
      selectGoods(event, row, index, accindex) {
        console.log(event, row, '1')
        var data = {
          arr_unit: event.arr_unit,
          goods_id: event.goods_id,
          goods_name: event.goods_name,
          goods_specs: event.specs,
          unit_id: event.unit_id,
          unit_name: event.unit_name,
          goods_num: event.quantity,
          goods_price: event.goods_price,
        }
        // Object.assign(row, event)
        Object.assign(row, data)
      },
      addRows(val, accindex) {
        console.log('添加rows', val)
        console.log(val)
        if (val.length !== 0) {
          val.forEach((item) => {
            var a = {
              arr_unit: item.arr_unit,
              goods_id: item.goods_id,
              goods_name: item.goods_name,
              goods_specs: item.specs,
              unit_id: item.unit_id,
              unit_name: item.unit_name,
              goods_num: item.quantity,
              goods_price: item.goods_price,
            }
            item.goodsName = item.goods_name
            this.form.acc_data[accindex].givelist.splice(
              this.form.acc_data[accindex].givelist.length - 1,
              0,
              a
            )
          })
          this.$message.success('添加成功')
        }
      },
      // 表格内单位切换
      unitChange(e, row) {
        console.log(e, row)
        let price = row.arr_unit.filter((item) => item.id == e)[0].sell_price
        let name = row.arr_unit.filter((item) => item.id == e)[0].unit_name
        row.goods_price = price
        row.unit_name = name
      },
      setUserId(val) {
        console.log(val, val.split(','))
        this.form.user_data = JSON.stringify(val.split(','))
        this.user_data = val.split(',').length
        // this.data.limit_count = this.data.limit_users.split(',').length
      },
      handleSet() {
        this.$refs.setComponent.showDialog = true
      },
      // 添加
      handlerAdd(row, index, accindex) {
        this.form.acc_data[accindex].givelist.splice(
          index + 1,
          0,
          JSON.parse(JSON.stringify(row))
        )
      },
      // 删除
      handlerDelet(row, index, accindex) {
        if (this.form.acc_data[accindex].givelist.length <= 1) {
          this.$message({
            message: '不能在删除了呀',
            type: 'warning',
          })
        } else {
          this.form.acc_data[accindex].givelist.splice(index, 1)
        }
      },
      handlerTiem(val) {
        console.log(val, '')
        if (val.length > 0) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
        console.log(this.form.start_time, this.form.end_time, '')
      },
    },
  }
</script>

<style></style>
